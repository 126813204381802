// Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className='App-footer '>
      <p>VarnaLove &copy; {new Date().getFullYear()} - Deepvarna India Private Limited. All Rights Reserved</p>
      {/* Add footer content here */}
    </footer>
  );
};

export default Footer;