// HomePage.js
import React from 'react';
import MapWrapper from './MapWrapper';

const HomePage = ({ data }) => {
  return (
    <div>
      <div className='App-home'>
        <p><strong>Emergency:</strong> Hi {data.name}, {data.user_name} has activated a Panic alert from the VarnaLove app, indicating that she may be in danger. Please urgently attempt to contact her and provide her live location to emergency services, if necessary. We are continiously tracking her possible location.</p>
      </div>
      {
        <MapWrapper data={data}/>
      }
    </div>
  );
};

export default HomePage;