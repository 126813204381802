// HomePageWrapper.js
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import NotFoundPage from '../404/NotFoundPage';
import ApiService from '../APIService/ApiService';
import HomePage from './HomePage';

const HomePageWrapper = () => {
    let params = useParams();
    if (params.id === "") {
        return <NotFoundPage />;
    } else {
        return <ValidateRequest />;
    }
};

const ValidateRequest = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    let params = useParams();
    useEffect(() => {
        const fetchData = async () => {
            console.log(params.id);
            try {
                const response = await ApiService.get(`https://api.varnalove.com/verify/panic/track/request/${params.id}`);
                if (response.success === true && response.data.length > 0) {
                    setData(response.data[0]);
                } else {
                    setData(""); // Setting empty array to show no data available.
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error
                return <NotFoundPage />;
            } finally {
                setLoading(false); // Update loading state when API call completes
            }
        };

        fetchData();

    }, [params.id]);

    if (params.id === "") {
        return <NotFoundPage />;
    }

    // Return JSX here to render the component
    return (
        <div>
            {/* Conditional rendering based on loading state */}
            {loading ? (
                <h2 style={{ height: '82.3vh', width: '100%' }}>Loading...</h2>
            ) : (
                <div>
                    {/* Check if data has value before rendering HomePage */}
                    {data ? (
                        <HomePage data={data} />
                    ) : (
                        <h2 style={{ height: '82.3vh', width: '100%' }}>No data available</h2>
                    )}
                </div>
            )}
        </div>
    );
};

export default HomePageWrapper;