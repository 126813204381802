// Header.js
import React from 'react';
import logo from '../images/logo.png';
const Header = () => {
  return (
    <header>
      <div className='App-header'>
          <a className="App-link" href="https://varnalove.com" target='_blank' rel="noreferrer">
				  <img className='App-logo' src={logo} alt="VarnaLove"/>
        </a>
      </div>
      {/* Add navigation links or other header content here */}
    </header>
  );
};

export default Header;