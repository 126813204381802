import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import Map from './Map';

const SOCKET_SERVER_URL = 'https://api.varnalove.com';

const MapWrapper = ({ data }) => {
  const [location, setLocation] = useState([]);
  const [isLocationUpdate, setIsLocationUpdate] = useState(false);

  useEffect(() => {
    const socket = io(`${SOCKET_SERVER_URL}?token=${data.token}`);

    socket.on('connect', () => {
      console.log('Socket connected successfully');

      // Subscribe to the locationTracking event
      socket.emit("locationTracking", () => {
        console.log("Location tracking event subscribed:");
      });

      // Litining to the locationTracking event
      socket.on('locationTracking', (locationData) => {
        console.log('Received location data:', locationData);
        // Handle the received locationn tracking data here
        setLocation(locationData.room[0].location);
      });

      // Litining to the updateLocation event
      socket.on("updateLocation", (locationData) => {
        console.log('Received location data:', locationData);
        // Handle the received location data here
        setLocation(prevLocation => [...prevLocation, locationData.location]);
        setIsLocationUpdate(true);
      });
    });  

    // Litining to the socket disconnection event
    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    // Clean up on unmount
    return () => {
      socket.disconnect(); // Disconnect the socket when the component unmounts
    };
  }, []);

  // Render your component with the received locationData
  return (
    <Map
      center={{ lat: data.latitude, lng: data.longitude }}
      zoom={5}
      data={[location, isLocationUpdate]}
    />
  );
};

export default MapWrapper;