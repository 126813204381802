import { Routes, Route } from "react-router-dom"
import './App.css';
import Header from './header/Header';
import Footer from './footer/Footer';
import NotFoundPage from './404/NotFoundPage';
import HomePageWrapper from "./home/HomePageWrapper";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="*" element = {<NotFoundPage />} />
        <Route path="/:id" element = {<HomePageWrapper />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
