// Map.js
import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import img from '../images/currentLocation.png';

const Map = ({ center, zoom, data }) => {
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [firstMarker, setFirstMarker] = useState(null);

  useEffect(() => {
    const [location, isLocationUpdate] = data;
    if (location.length > 0 && map) {
      const bounds = new window.google.maps.LatLngBounds();
      const firstLocation = location[0];
      const lastLocation = location[location.length - 1];

      // Add marker for the first location with initial position infowindow
      if (isLocationUpdate === false) {
        const firstMarker = new window.google.maps.Marker({
          position: { lat: Number(firstLocation.latitude), lng: Number(firstLocation.longitude) },
          map: map,
        });
  
        bounds.extend(firstMarker.getPosition());
  
        // Open info window for the first marker
        const infoWindow = new window.google.maps.InfoWindow({
          content: 'Panic alert activated locaion',
        });
        infoWindow.open(map, firstMarker);
  
        firstMarker.addListener("click", () => {
          infoWindow.open({
            anchor: firstMarker,
            map,
          });
        });
        setFirstMarker(firstMarker);
      }

      // Removing current locaiton marker if exist before drawing again.
      if (isLocationUpdate === true) {
        if (markers[1] !== null) {
          markers[1].setMap(null);
        }
      }

      // Draw current location markers
      const currentLocationMarker = new window.google.maps.Marker({
        position: { lat: Number(lastLocation.latitude), lng: Number(lastLocation.longitude) },
        map: map,
        icon: {
          url: img,
          scaledSize: new window.google.maps.Size(24, 24),
          anchor: new window.google.maps.Point(12, 12)
        },
      });
      
      bounds.extend(currentLocationMarker.getPosition()); 

      // Draw path between all locations
      const pathCoordinates = location.map(locationData => ({
        lat: Number(locationData.latitude),
        lng: Number(locationData.longitude),
      }));
      const path = new window.google.maps.Polyline({
        path: pathCoordinates,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
      });

      setMarkers([firstMarker, currentLocationMarker]);

      // Extend bounds to include all markers
      markers.forEach(marker => {
        if (marker) {
          bounds.extend(marker.getPosition());
        }
      });

      // Fit map to show all markers and path
      map.fitBounds(bounds);
      path.setMap(map);
    }
  }, [data, map]);

  const handleApiLoaded = (map, maps) => {
    setMap(map);
  };

  return (
    <div style={{ height: '75vh', width: '100%' }}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBRVfRIgsNiZX8-lsBLbz37DC_Tuuwor5U' }}
            defaultCenter={center}
            defaultZoom={zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        ></GoogleMapReact>
    </div>
  );
};

Map.defaultProps = {
  center: {
    lat: 0, // Default latitude
    lng: 0, // Default longitude
  },
  zoom: 5, // Default zoom level
};

export default Map;