// NotFoundPage.js
import React from 'react';

const NotFoundPage = () => {
  return (
    <div>
      <div className='App-404'>
        <p>
            <h1>404</h1>
            <br/>
            <h3>Page Not Found</h3>
        </p>
      </div>
    </div>
  );
};

export default NotFoundPage;